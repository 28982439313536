<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.supp')" />
    <div class="page-content container-fluid">
      <div class="row" v-if="info.whitelabel_id == null">
        <div class="col-12">
          <div class="card">
            <div class="row m-0 col-border-xl">
              <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="card-body">
                  <div class="icon-rounded icon-rounded-info float-left m-r-20">
                    <i class="far fa-dot-circle"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">
                    {{ opens.length }}
                  </h5>
                  <h6 class="text-muted m-t-10">{{$t('calls-component.opened')}}</h6>
                  <div
                    class="progress progress-new-account mt-4"
                    style="height: 7px"
                  >
                    <div
                      class="progress-bar bg-info"
                      role="progressbar"
                      :style="`width:${opensPercent}%;`"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <small class="text-muted float-left m-t-5 mb-3">
                    Status
                  </small>
                  <small
                    class="text-muted float-right m-t-5 mb-3 counter append-percent"
                    >{{ opensPercent }}</small
                  >
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="card-body">
                  <div
                    class="icon-rounded icon-rounded-success float-left m-r-20"
                  >
                    <i class="zmdi zmdi-check-all zmdi-hc-fw"></i>
                  </div>
                  <h5 class="card-title m-b-5 counter" data-count="0">
                    {{ answered.length }}
                  </h5>
                  <h6 class="text-muted m-t-10">{{$t('calls-component.answered')}}</h6>
                  <div
                    class="progress progress-active-sessions mt-4"
                    style="height: 7px"
                  >
                    <div
                      class="progress-bar bg-success"
                      role="progressbar"
                      :style="`width:${answeredPercent}%;`"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <small class="text-muted float-left m-t-5 mb-3">
                    Status
                  </small>
                  <small
                    class="text-muted float-right m-t-5 mb-3 counter append-percent"
                    >{{ answeredPercent }}</small
                  >
                </div>
              </div>
              <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="card-body">
                  <h6 class="text-muted m-t-10">Total</h6>
                  <h2 class="card-title m-b-5 counter" data-count="0">
                    {{ tickets.length }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <form class="row" _lpchecked="1">
                <div class="col-md-4">
                  <label class="sr-only" for="inlineFormInputName2"
                    >{{$t('generic-str.search-by.default')}}</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ID..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      v-model="form.id"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary btn-icon"
                        type="button"
                        @click="fetch(1)"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label class="sr-only" for="status">Status</label>
                  <select
                    class="form-control"
                    id="status"
                    v-model="form.status"
                    @change="fetch(1)"
                  >
                    <option selected value>Status</option>
                    <option value="open">{{$t('generic-str.status.lbl-open')}}</option>
                    <option value="closed">{{$t('generic-str.status.lbl-closed')}}</option>
                    <option value="archived">{{$t('generic-str.status.lbl-filed')}}</option>
                  </select>
                </div>
              </form>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <table v-if="fetched && tickets.length" class="table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>{{$t('generic-str.subject')}}</th>
                      <th>{{$t('generic-str.customer')}}</th>
                      <th>{{$t('generic-str.date')}}</th>
                      <th>{{$t('calls-component.last-interaction')}}</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ticket in tickets" :key="ticket.id">
                      <td>{{ ticket.id | minifyUuid }}</td>
                      <td>{{ ticket.subject }}</td>
                      <td>{{ ticket.account.name }}</td>
                      <td>{{ ticket.created_at | formatDate24 }}</td>
                      <td><span v-if="ticket.last_reply">{{ ticket.last_reply.user.name }} ({{ ticket.updated_at | relativeDate }})</span></td>
                      <td>
                        <span
                          class="badge badge-info"
                          v-if="ticket.status == 'open'"
                          >{{$t('generic-str.status.lbl-open')}}</span
                        >
                        <span
                          class="badge badge-danger"
                          v-else-if="ticket.status == 'closed'"
                          >{{$t('generic-str.status.lbl-closed')}}</span
                        >
                        <span
                          class="badge badge-dark"
                          v-else-if="ticket.status == 'archived'"
                          >{{$t('generic-str.status.lbl-filed')}}</span
                        >
                      </td>
                      <td>
                        <router-link :to="`support/${ticket.id}`">
                          <i class="fa fa-comment"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else-if="fetched" class="text-center table-placeholder">
                  <i class="fas fa-life-ring font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('ticket-component.none')}}</h5>
                </div>
                <div
                  v-else
                  class="qt-block-ui relative"
                  style="padding: 120px"
                />
                <br />
                <pagination :lastPage="pages" @change="fetch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TicketService from '@/services/ticket.service';
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      fetched: false,
      tickets: [],
      id: '',
      form: {
        id: '',
        status: 'open',
        page: 1,
      },
      pages: 1,
      exporting: false,
      isSending: false,
      opens: [],
      opensPercent: 0,
      answeredPercent: 0,
      answered: [],
    };
  },
  computed: {
    info() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    goDetail(message) {
      this.$router.push({ name: 'TicketMessage', params: { m: message } });
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      TicketService.getAllTickets(this.form).then(
        (response) => {
          this.fetched = true;
          this.tickets = response.data;
          this.opens = [];
          this.answered = [];
          // this.pages = response.last_page;
          if (this.tickets.length > 0) {
            this.tickets.forEach((element) => {
              if (element.status === 'open') {
                this.opens.push(element);
              }

              if (element.status === 'answered') {
                this.answered.push(element);
              }
            });
            this.opensPercent =
              (this.opens.length / this.tickets.length) * 100;
            this.answeredPercent =
              (this.answered.length / this.tickets.length) * 100;
          }
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
td a i {
  font-size: 1.19rem;
}
</style>
